import { TextField } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

type CustomTextFieldProps = {
  error?: boolean;
};

export const CustomTextField = styled(TextField)<CustomTextFieldProps>(
  ({ error }) => {
    const theme = useTheme(); // Access the theme

    return {
      "&> .MuiInputBase-root": {
        ...(!error && {
          backgroundColor: theme.palette.mode === "dark" ? "#333" : "#EEE",
          // color: theme.palette.mode === "dark" ? "#EEE" : "#333",
        }),
        borderRadius: 20,
        paddingTop: 3,
        paddingBottom: 3,
      },
    };
  }
);
