import {
  BusinessCenter,
  Code,
  ColorLens,
  DataObject,
  Diversity1,
  Email,
  FmdGoodRounded,
  FormatListBulleted,
  ForumRounded,
  HomeRounded,
  Language,
  Person,
  PhoneEnabledRounded,
  Source,
} from "@mui/icons-material";
import lms from "src/assets/images/projects/lms.png";
import dorsaCloudImage from "src/assets/images/projects/dorsa-cloud.png";
import aminAfzarJavan from "src/assets/images/projects/amaj.png";
import airsheet from "src/assets/images/projects/airsheet.png";
import nikahang from "src/assets/images/projects/nikahang.png";
import sgi from "src/assets/images/projects/sgi.png";
import apadana from "src/assets/images/projects/apadana.png";

export const barItems = [
  { title: "barItems.home", path: "/", icon: HomeRounded },
  { title: "barItems.about", path: "/about", icon: Person },
  { title: "barItems.services", path: "/services", icon: FormatListBulleted },
  { title: "barItems.portfolio", path: "/portfolio", icon: BusinessCenter },
  { title: "barItems.contact", path: "/contact", icon: ForumRounded },
];

export const aboutMe = "aboutMe.text";

export const details = [
  { title: "details.birthday", value: "details.birthday.value" },
  { title: "details.age", value: "details.age.value" },
  { title: "details.website", value: "details.website.value" },
  { title: "details.email", value: "details.email.value" },
  { title: "details.degree", value: "details.degree.value" },
  { title: "details.phone", value: "details.phone.value" },
  { title: "details.location", value: "details.location.value" },
  { title: "details.freelance", value: "details.freelance.value" },
];

export const skills = [
  { title: "HTML 5, CSS", value: 80 },
  { title: "React JS", value: 85 },
  { title: "Next JS", value: 75 },
  { title: "Node JS, Express, Nest JS", value: 65 },
  { title: "Mongo DB", value: 60 },
  { title: "Project Management", value: 60 },
];

export const educations = [
  {
    title: "educations.computerScience",
    date: "educations.computerScience.date",
    text: "educations.computerScience.text",
  },
  {
    title: "educations.webDesign",
    date: "educations.webDesign.date",
    text: "educations.webDesign.text",
  },
  {
    title: "educations.backendDevelopment",
    date: "educations.backendDevelopment.date",
    text: "educations.backendDevelopment.text",
  },
  {
    title: "educations.frontendDevelopment",
    date: "educations.frontendDevelopment.date",
    text: "educations.frontendDevelopment.text",
  },
];

export const experience = [
  {
    title: "experience.emad",
    date: "experience.emad.date",
    text: "experience.emad.text",
  },
  {
    title: "experience.vadud",
    date: "experience.vadud.date",
    text: "experience.vadud.text",
  },
  {
    title: "experience.amaj",
    date: "experience.amaj.date",
    text: "experience.amaj.text",
    list: [
      "experience.amaj.list.1",
      "experience.amaj.list.2",
      "experience.amaj.list.3",
      "experience.amaj.list.4",
      "experience.amaj.list.5",
    ],
  },
  {
    title: "experience.nextera",
    date: "experience.nextera.date",
    text: "experience.nextera.text",
  },
];

export const services = [
  {
    icon: Code,
    title: "services.webDesign",
    description: "services.webDesign.description",
  },
  {
    icon: ColorLens,
    title: "services.ui/uxDesign",
    description: "services.ui/uxDesign.description",
  },
  {
    icon: DataObject,
    title: "services.frontendDevelopment",
    description: "services.frontendDevelopment.description",
  },
  {
    icon: Source,
    title: "services.backendDevelopment",
    description: "services.backendDevelopment.description",
  },
  {
    icon: Diversity1,
    title: "services.projectManagement",
    description: "services.projectManagement.description",
  },
];

export const projects = [
  {
    image: lms,
    title: "projects.lms",
    link: "https://lms-dev.posechecker.com/",
  },
  {
    image: dorsaCloudImage,
    title: "projects.dorsaCloud",
    link: "https://my.dorsa.cloud/",
  },
  {
    image: aminAfzarJavan,
    title: "projects.amaj",
    link: "https://www.aminafzar.com/",
  },
  {
    image: airsheet,
    title: "projects.airsheet",
    link: "https://www.airsheet.ir/",
  },
  {
    image: nikahang,
    title: "projects.nikahang",
  },
  {
    image: sgi,
    title: "projects.sgi",
  },
  {
    image: apadana,
    title: "projects.apadana",
    link: "https://panel.apadanacloud.com/",
  },
];

export const contacts = [
  {
    icon: PhoneEnabledRounded,
    title: "contact.phone",
    text: "contact.phone.value",
    link: "tel: +98 9389087876",
  },
  {
    icon: FmdGoodRounded,
    title: "contact.office",
    text: "contact.office.value",
    link: "https://goo.gl/maps/afqZKx2bNWT1bz84A",
  },
  {
    icon: Email,
    title: "contact.email",
    text: "contact.email.value",
    link: "mailto: sh.abolfazl.ir@gmail.com",
  },
  {
    icon: Language,
    title: "contact.website",
    text: "contact.website.value",
    link: "http://abolfazl-shafaghi.com",
  },
];
