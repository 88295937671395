import { FC, useState } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type ServiceCardPropsType = { title: string; icon: any; description: string };

export const ServiceCard: FC<ServiceCardPropsType> = ({
  title,
  icon: Icon,
  description,
}) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Stack
        component={Paper}
        elevation={hover ? 5 : 2}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        borderRadius={3}
        justifyContent="center"
        alignItems="center"
        spacing={4}
        p={4}
      >
        <Stack justifyContent="center" alignItems="center" spacing={1}>
          <Icon
            color="primary"
            sx={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              p: 1,
            }}
          />
          <Typography variant="title4" fontWeight={700} noWrap>
            {t(title)}
          </Typography>
        </Stack>
        <Typography
          variant="subtitle2"
          textAlign="center"
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: "4",
            overflow: "hidden",
          }}
        >
          {t(description)}
        </Typography>
      </Stack>
    </Grid>
  );
};
