import { FC } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import BlackLogo from "src/assets/images/Black-Logo.png";
import WhiteLogo from "src/assets/images/White-Logo.png";
import { barItems } from "../organism/constant";
import { useTranslation } from "react-i18next";

export const Sidebar: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Stack
      display={{ xs: "none", lg: "flex" }}
      alignItems="center"
      justifyContent="space-between"
      bgcolor="customPalette.sidebarBG"
    >
      <Box px={2} pt={4}>
        <img
          src={theme.palette.mode === "dark" ? WhiteLogo : BlackLogo}
          alt="logo"
          style={{ width: 240, height: 200 }}
        />
      </Box>
      <Stack spacing={2} width="100%">
        {barItems.map(({ title, icon: Icon, path }) => {
          const active = path === pathname;
          return (
            <Link
              to={path}
              key={title}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Button size="large" fullWidth sx={{ direction: "row" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  width={180}
                  spacing={2}
                  sx={{ "& > *:not(:last-child)": { ml: 1 } }}
                >
                  <Icon
                    sx={{
                      fontSize: 30,
                      color: active ? "primary" : "customPalette.disabled",
                    }}
                  />
                  <Typography
                    color={active ? "primary" : "customPalette.disabled"}
                    variant="subtitle0"
                    fontWeight={700}
                    noWrap
                  >
                    {t(title).charAt(0).toUpperCase() + t(title).slice(1)}
                  </Typography>
                </Stack>
              </Button>
            </Link>
          );
        })}
      </Stack>
      <Box height={150}></Box>
    </Stack>
  );
};
