import { FC, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CustomTextField } from "../atom/CustomTextfield";
import { useTranslation } from "react-i18next";

type LoginDialogPropsType = { open: boolean; onClose: () => void };

export const LoginDialog: FC<LoginDialogPropsType> = ({ open, onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    setUsername("");
    setPassword("");
    onClose();
  };

  const submitHandler = () => {
    if (username === "abolfazl" && password === "admin123") {
      toast.success("Welcome Abolfazl");
      handleClose();
    } else {
      toast.error(t("usernameOrPasswordIsIncorrect"));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{ sx: { width: "100%" } }}
    >
      <DialogTitle align="center" fontWeight={700}>
        {t("signInToYourAccount")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ px: 1, pb: 0.3 }}>
          {t("username")}
        </DialogContentText>
        <CustomTextField
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <DialogContentText sx={{ px: 1, pb: 0.3 }}>
          {t("password")}
        </DialogContentText>
        <CustomTextField
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions sx={{ "& > *:not(:last-child)": { ml: 1 } }}>
        <Button variant="outlined" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          disabled={!username || !password}
          onClick={submitHandler}
        >
          {t("submit")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
