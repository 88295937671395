import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "src/pages/About";
import Contact from "src/pages/Contact";
import Portfolio from "src/pages/Portfolio";
import Services from "src/pages/Services";
import { DashboardTemplate } from "../components/layout/DashboardTemplate";
import Home from "../pages/Home";

const getPage = <T = any,>(PageComponent: FC<any>, pageProps?: T) => (
  <DashboardTemplate>
    <PageComponent {...pageProps} />
  </DashboardTemplate>
);

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={getPage(Home)} />
        <Route path="/about" element={getPage(About)} />
        <Route path="/services" element={getPage(Services)} />
        <Route path="/portfolio" element={getPage(Portfolio)} />
        <Route path="/contact" element={getPage(Contact)} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
