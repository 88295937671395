import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { CalendarToday } from "@mui/icons-material";
import { useIsPersian } from "../hooks/isPersian";
import { useTranslation } from "react-i18next";

type CustomTimeLinePropsType = {
  list: { title: string; date: string; text: string; list?: string[] }[];
};

export const CustomTimeLine: FC<CustomTimeLinePropsType> = ({ list }) => {
  const isPersian = useIsPersian();
  const { t } = useTranslation();

  return (
    <Timeline sx={{ "& .MuiTimelineItem-root:before": { display: "none" } }}>
      {list.map((item, index) => (
        <TimelineItem key={index} sx={{ mb: 3 }}>
          <TimelineSeparator>
            <TimelineDot sx={{ p: 0.6, mb: 0, bgcolor: "primary.main" }} />
            <TimelineConnector
              sx={{
                width: 2,
                bgcolor: "primary.main",
                mb: index !== list.length - 1 ? -5 : 0,
              }}
            />
          </TimelineSeparator>
          <TimelineContent sx={{ mt: 0.5 }}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ "& > *:not(:last-child)": { ml: isPersian ? 1 : 0 } }}
            >
              <CalendarToday fontSize="small" />
              <Typography>{t(item.date)}</Typography>
            </Stack>
            <Typography
              variant="subtitle1"
              fontWeight={700}
              textAlign={isPersian ? "right" : "left"}
              noWrap
            >
              {t(item.title)}
            </Typography>
            {item.list ? (
              <Stack spacing={1}>
                {item.list.map((item, index) => (
                  <Typography
                    key={index}
                    textAlign={isPersian ? "right" : "left"}
                  >
                    - {t(item)}
                  </Typography>
                ))}
              </Stack>
            ) : (
              <Typography textAlign={isPersian ? "right" : "left"}>
                {t(item.text)}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
