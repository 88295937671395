import { FC } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { services } from "src/components/organism/constant";
import { ServiceCard } from "src/components/organism/ServiceCard";
import { useTranslation } from "react-i18next";

const Services: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stack py={5}>
        <Typography variant="title0" fontWeight={700}>
          {t("services")}
        </Typography>
        <Stack spacing={0.5} pt={0.5}>
          <Box height={5} width={80} bgcolor="primary.main" />
          <Box height={5} width={50} bgcolor="primary.main" />
        </Stack>
      </Stack>
      <Grid container spacing={3} pb={1}>
        {services.map(({ title, icon: Icon, description }, index) => (
          <ServiceCard
            key={index}
            title={title}
            icon={Icon}
            description={description}
          />
        ))}
      </Grid>
    </>
  );
};

export default Services;
