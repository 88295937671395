import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

type directionType = "rtl" | "ltr";

const createEmotionCache = (direction?: directionType) => {
  createCache({ key: "css" });
  let result: any = { key: "css" };

  if (direction === "rtl") {
    result = { key: "muirtl", stylisPlugins: [prefixer, rtlPlugin] };
  }
  return createCache(result);
};

export default createEmotionCache;
