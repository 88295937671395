import { FC } from "react";
import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  aboutMe,
  details,
  educations,
  experience,
  skills,
} from "src/components/organism/constant";
import { CustomTimeLine } from "src/components/atom/CustomTimeLine";
import { useTranslation } from "react-i18next";

const About: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Stack py={5}>
        <Typography variant="title0" fontWeight={700}>
          {t("aboutMe")}
        </Typography>
        <Stack spacing={0.5} pt={0.5}>
          <Box height={5} width={80} bgcolor="primary.main" />
          <Box height={5} width={50} bgcolor="primary.main" />
        </Stack>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="title1" fontWeight={700}>
          {t("aboutMe.title.name") + " "}
          <span style={{ color: theme.palette.primary.main }}>
            {t("aboutMe.title.job")}
          </span>
        </Typography>
        <Typography variant="subtitle1" lineHeight={1.3}>
          {t(aboutMe)}
        </Typography>
      </Stack>
      <Grid container spacing={3} py={5}>
        <Grid item xs={12} md={7.5}>
          <Grid container rowSpacing={1} columnSpacing={3}>
            {details.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Typography variant="subtitle1" noWrap>
                  <span style={{ fontWeight: "bold" }}>{t(item.title)}: </span>
                  {t(item.value)}
                </Typography>
                <Divider light sx={{ backgroundColor: "#BBB", mt: 1 }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs>
          <Stack spacing={2}>
            {skills.map((item, index) => (
              <Stack spacing={0.5} key={index}>
                <Stack
                  direction={
                    t("switchLanguage") === "EN" ? "row-reverse" : "row"
                  }
                  justifyContent="space-between"
                >
                  <Typography>{item.title}</Typography>
                  <Typography>{item.value}%</Typography>
                </Stack>
                <LinearProgress
                  variant="determinate"
                  value={item.value}
                  sx={{ height: 8, borderRadius: 1 }}
                />
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3}>
        <Grid item xs>
          <Typography variant="title3" fontWeight={700}>
            {t("education")}
          </Typography>
          <Paper elevation={3}>
            <CustomTimeLine list={educations} />
          </Paper>
        </Grid>
        <Grid item xs>
          <Typography variant="title3" fontWeight={700}>
            {t("experience")}
          </Typography>
          <Paper elevation={3}>
            <CustomTimeLine list={experience} />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default About;
