import { useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import createEmotionCache from "./utils/createEmotionCache";
import Router from "./routes/Router";
import { useIsPersian } from "./components/hooks/isPersian";

import "./assets/global.css";
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
  const isPersian = useIsPersian();

  useEffect(() => {
    if (isPersian) document.body.dir = "rtl";
    else document.body.dir = "ltr";
  }, [isPersian]);

  return (
    <CacheProvider value={createEmotionCache()}>
      <CssBaseline />
      <Router />
      <ToastContainer limit={3} autoClose={10000} />
    </CacheProvider>
  );
};

export default App;
