import { FC } from "react";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import HomeImage from "src/assets/images/my-image.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center" height="100%">
      <Grid container gap={4}>
        <Grid item xs={12} sm order={{ xs: 2, sm: 1 }}>
          <Stack
            spacing={2}
            justifyContent="center"
            height="100%"
            minWidth={300}
          >
            <Typography variant="title2" fontWeight={700}>
              {t("homeAbout.title.hello") + " "}
              <span style={{ color: theme.palette.primary.main }}>
                {t("homeAbout.title.name")}
              </span>
              {" " + t("homeAbout.title.is")}
            </Typography>
            <Typography variant="title4">{t("homeAbout.title.job")}</Typography>
            <Typography variant="subtitle1">{t("homeAbout.text")}</Typography>
            <Box>
              <Button
                variant="contained"
                size="large"
                component={Link}
                to="/about"
                sx={{ borderRadius: 4, mt: 2 }}
              >
                {t("moreAboutMe")}
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item order={{ xs: 1, sm: 2 }} xs={12} sm={5}>
          <Stack width="100%" alignItems="center">
            <Box
              sx={{
                "--b": "8px",
                "--s": "100px",
                "--g": "20px",
                "--c": theme.palette.primary.main,
                padding: "calc(var(--b) + var(--g))",
                backgroundImage:
                  "conic-gradient(from  90deg at top    var(--b) left  var(--b),#0000 25%,var(--c) 0),conic-gradient(from -90deg at bottom var(--b) right var(--b),#0000 25%,var(--c) 0)",
                backgroundPosition:
                  "var(--_p,0%) var(--_p,0%),calc(100% - var(--_p,0%)) calc(100% - var(--_p,0%))",
                backgroundSize: "var(--s) var(--s)",
                backgroundRepeat: "no-repeat",
                transition:
                  "background-position .3s var(--_i,.3s), background-size .3s calc(.3s - var(--_i,.3s))",
                "&:hover": {
                  backgroundSize: "calc(100% - var(--g)) calc(100% - var(--g))",
                  "--_p": "calc(var(--g)/2)",
                  "--_i": "0s",
                },
                width: { xs: 300, md: 400 },
                height: { xs: 330, md: 450 },
              }}
            >
              <img
                src={HomeImage}
                alt="profile"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Home;
