import { FC } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { contacts } from "src/components/organism/constant";
import { CustomTextField } from "src/components/atom/CustomTextfield";
import { useTranslation } from "react-i18next";

const Contact: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stack py={5}>
        <Typography variant="title0" fontWeight={700}>
          {t("contactMe")}
        </Typography>
        <Stack spacing={0.5} pt={0.5}>
          <Box height={5} width={80} bgcolor="primary.main" />
          <Box height={5} width={50} bgcolor="primary.main" />
        </Stack>
      </Stack>
      <Stack alignItems="center" spacing={8} pb={1}>
        <Stack alignItems="center" width="100%" spacing={2}>
          <Typography variant="title1" color="primary" fontWeight={700}>
            {t("haveAnyQuestions")}
          </Typography>
          <Typography variant="subtitle2">{t("imAtYourService")} </Typography>
          <Grid container rowSpacing={4}>
            {contacts.map(({ icon: ItemIcon, title, text, link }, index) => (
              <Grid key={index} item xs={6} md={3}>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  spacing={1.5}
                >
                  <IconButton
                    href={link}
                    target={t(title) === "Office" ? "_blank" : "_parent"}
                  >
                    <ItemIcon color="primary" fontSize="large" />
                  </IconButton>
                  <Typography variant="title4">{t(title)}</Typography>
                  <Typography
                    variant="subtitle2"
                    maxWidth={250}
                    noWrap
                    sx={{ textOverflow: "ellipsis" }}
                  >
                    {t(text)}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack alignItems="center" spacing={1}>
          <Typography variant="title1" color="primary" fontWeight={700}>
            {t("sendMeAnEmail")}
          </Typography>
          <Typography variant="subtitle2">
            {t("veryResponsiveToMessage")}
          </Typography>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 0, sm: 2 }}
            px={{ xs: 0, md: 5 }}
          >
            <Grid item xs={12} sm={6}>
              <CustomTextField placeholder={t("message") || ""} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField placeholder={t("email") || ""} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField placeholder={t("subject") || ""} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <CustomTextField
                placeholder={t("message") || ""}
                fullWidth
                multiline
                rows={3}
                sx={{ "& .MuiInputBase-root": { p: 2 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" sx={{ borderRadius: 4, py: 1 }}>
                {t("sendMessage")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default Contact;
