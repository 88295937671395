import { FC, useState } from "react";
import {
  Box,
  Container,
  Fab,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import i18n from "src/utils/i18n";
import { useTranslation } from "react-i18next";
import { LightMode, NightsStay } from "@mui/icons-material";
import { Sidebar } from "./Sidebar";
import { LoginDialog } from "../organism/LoginDialog";
import { generateTheme } from "src/configs/theme";
import { Navbar } from "./Navbar";
import { useIsPersian } from "../hooks/isPersian";

export type DashboardTemplatePropsType = {
  children?: JSX.Element;
};

export const DashboardTemplate: FC<DashboardTemplatePropsType> = ({
  children,
}) => {
  const [mode, setMode] = useState<"dark" | "light">("dark");

  const toggleTheme = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const { t } = useTranslation();
  const isPersian = useIsPersian();

  return (
    <ThemeProvider theme={generateTheme(mode, isPersian ? "fa" : "en")}>
      <Paper sx={{ borderRadius: 0 }}>
        <Stack
          direction="row"
          width="100%"
          height="100vh"
          maxHeight="100vh"
          overflow="hidden"
        >
          <Sidebar />
          <Stack width="100%" position="relative">
            <Box sx={{ height: "100%", pt: 2, overflowY: "overlay" }}>
              <Container maxWidth="lg" sx={{ height: "100%" }}>
                <Box sx={{ height: "calc(100% - 45px)", mb: 10 }}>
                  {children}
                </Box>
              </Container>
            </Box>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                position: "fixed",
                top: { xs: 15, md: 25 },
                "& > *:not(:last-child)": { ml: 1 },
                [isPersian ? "left" : "right"]: { xs: 20, md: 30 },
              }}
            >
              <Fab
                sx={{ color: "white", width: 40, height: 40 }}
                onClick={toggleTheme}
                color="primary"
              >
                {mode === "dark" ? <LightMode /> : <NightsStay />}
              </Fab>
              <Fab
                sx={{ color: "white", width: 40, height: 40 }}
                color="primary"
                onClick={() =>
                  i18n.changeLanguage(t("switchLanguage").toLowerCase())
                }
              >
                <Typography fontSize={18} fontWeight={700}>
                  {t("switchLanguage")}
                </Typography>
              </Fab>
              {/* <Fab
                sx={{ color: "white", width: 40, height: 40 }}
                onClick={() => setOpenLoginDialog(true)}
                color="primary"
              >
                <Person />
              </Fab> */}
            </Stack>
            <Navbar />
          </Stack>
        </Stack>
        <LoginDialog
          open={openLoginDialog}
          onClose={() => setOpenLoginDialog(false)}
        />
      </Paper>
    </ThemeProvider>
  );
};
