import { FC, SyntheticEvent, useEffect, useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { barItems } from "../organism/constant";
import { useTranslation } from "react-i18next";

export const Navbar: FC = () => {
  const [value, setValue] = useState("home");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const currentButton = barItems.find((item) => item.path === pathname);
    if (currentButton) setValue(t(currentButton.title) || "");
  }, [pathname, t]);

  return (
    <BottomNavigation
      sx={{
        bgcolor: "customPalette.sidebarBG",
        borderRadius: 3,
        position: "fixed",
        bottom: 10,
        left: "50%",
        transform: "translateX(-50%)",
        display: { xs: "flex", lg: "none" },
      }}
      value={value}
      onChange={handleChange}
    >
      {barItems.map(({ title, icon: Icon, path }) => {
        return (
          <BottomNavigationAction
            key={t(title)}
            label={t(title).charAt(0).toUpperCase() + t(title).slice(1)}
            value={t(title)}
            icon={<Icon fontSize="small" />}
            component={Link}
            to={path}
            sx={{ whiteSpace: "nowrap" }}
          />
        );
      })}
    </BottomNavigation>
  );
};
