import { FC } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { InsertLink } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type ProjectCardPropsType = { image: string; title: string; link?: string };

export const ProjectCard: FC<ProjectCardPropsType> = ({
  image,
  title,
  link,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "relative",
        border: 5,
        borderColor: "customPalette.border",
        borderRadius: 2,
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderImageRepeat: "no-repeat",
        height: 220,
        minWidth: 300,
        maxWidth: 450,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          px: 1,
          textDecoration: "none",
        }}
      >
        <Typography variant="subtitle2" fontWeight={700} color="primary.main">
          {t(title)}
        </Typography>
        {link && (
          <IconButton component={Link} to={link} target="_blank">
            <InsertLink color="primary" sx={{ transform: "rotate(40deg)" }} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};
