import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ProjectCard } from "src/components/organism/ProjectCard";
import { projects } from "src/components/organism/constant";

const Portfolio: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={3} pb={2}>
      <Stack py={5}>
        <Typography variant="title0" fontWeight={700}>
          {t("portfolio")}
        </Typography>
        <Stack spacing={0.5} pt={0.5}>
          <Box height={5} width={80} bgcolor="primary.main" />
          <Box height={5} width={50} bgcolor="primary.main" />
        </Stack>
      </Stack>
      <Typography variant="title4">{t("myLastProjects")}:</Typography>
      <Grid
        container
        rowSpacing={{ xs: 2, md: 2 }}
        columnSpacing={{ xs: 0, sm: 2 }}
        px={2}
      >
        {projects.map(({ image, title, link }, index) => (
          <Grid item xs key={index}>
            <ProjectCard image={image} title={title} link={link} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Portfolio;
